import '../../../style/radio-buttons.css'
import Currency from 'react-currency-formatter'
import Icon from 'react-icons-kit'
import React, { Fragment } from 'react'
import { Badge, Col, Collapse, Input, Row } from 'reactstrap'
import { ic_swap_horiz } from 'react-icons-kit/md/ic_swap_horiz'
import { injectState, provideState } from 'reaclette'

import BindingChoice from './bindingChoice'
import Subscription from './subscription'
import { CardFixedWidth } from '../../../style/styled-components'
import { getProductsList,
  PAID_PERIOD,
  SUBSCRIPTION
} from '../../purchase-utils'
import ExistingQuote from '../../components/existingQuote'

const withState = provideState({
  initialState: () => ({
    products: []
  }),
  effects: {
    async initialize(effects) {
      await effects.getProducts()
      await effects.setPreSelectedProduit()
    },
    async getProducts(effects) {
      try {
        this.state.products = await getProductsList(this.props.role)
      } catch (error) {
        effects.handleError(error)
      }
    },
    async setPreSelectedProduit(effects) {
      if (this.props.preSelectedProduct) {
        const product = this.state.products.find(
          product => product.id === this.props.preSelectedProduct
        )
        effects.addToOrder(product)
        effects._addToOrder(product, 1)
      }
    },
    addOrder(effects, product) {
      effects.addToOrder(product)
      // Add XO Proxy (gift) with premium
      if (product.id === 'premium') {
        const xoProxy = this.state.products.find(
          product => product.id === 'xo-proxy'
        )
        const giftProxy = {
          ...xoProxy,
          planFormula: { name: 'XO Proxy (Gift with Premium)', amount: 0 }
        }
        effects.addToOrder(giftProxy)
      }
      effects._addToOrder(product, 1)
    }
  },
  computed: {
    productsBuyable: ({ products }) =>
      products.filter(product => product.isBuyable),
    selectedProduct: ({ order }) => order[0],
    showQuantityInput: ({ _order, selectedProduct }) => // TODO
      (_order && selectedProduct) &&
      (((!_order.paymentModel.type || _order.paymentModel.type === SUBSCRIPTION) &&
        selectedProduct.isBuyableByQuantityWithSubscription) ||
      (_order.paymentModel.type === PAID_PERIOD && selectedProduct.isBuyableByQuantityWithPrepaid))
  }
})

const PlanDetails = ({
  effects,
  state,
  account,
  country,
  currency,
  handleSelfbound,
  initialOrder,
  order,
  resetOrder,
  role,
  selfbound,
  updateOrder
}) => (
  <Fragment>
    <Row>
      <Col>
        <h4>
          Product required details <ExistingQuote />
        </h4>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="text-muted">Choose your product</p>
      </Col>
    </Row>
    <h5>
      {state.selectedProduct && (
        <Fragment>
          {state.selectedProduct.icon && (
            <img
              alt="logo product"
              height="30"
              src={state.selectedProduct.icon}
            />
          )}
          <Badge color="light" style={{ fontSize: '16px' }}>
            <span style={{ color: '#b42626' }}>
              {state.selectedProduct.name}
            </span>
          </Badge>
        </Fragment>
      )}
      <Icon
        icon={ic_swap_horiz}
        size={25}
        style={{ verticalAlign: 'middle' }}
      />
      &nbsp;
      {state.selectedProduct ? (
        <span
          style={{ cursor: 'pointer' }}
          className="change-plan"
          onClick={resetOrder}
        >
          change plan
        </span>
      ) : (
        <span>Select a product</span>
      )}
    </h5>
    <Collapse isOpen={!state.selectedProduct}>
      <Row>
        {state.productsBuyable.map(product => (
          <Col md="3" key={product.id}>
            <CardFixedWidth
              className="hvr-grow"
              body
              onClick={() => effects.addOrder(product)}
              style={{ cursor: 'pointer' }}
            >
              <p
                style={{ color: '#01337f', fontWeight: 'bold' }}
                className="text-center"
              >
                {product.name}
              </p>
              {product.montly && product.montly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.montly.amount}
                        currency={currency}
                      />
                      /mo.
                    </Badge>
                  </h4>
                </div>
              )}
              {product.yearly && product.yearly.amount > 0 && (
                <div className="text-center">
                  <h4>
                    <Badge color="light">
                      <Currency
                        quantity={product.yearly.amount}
                        currency={currency}
                      />
                      /year
                    </Badge>
                  </h4>
                </div>
              )}
            </CardFixedWidth>
          </Col>
        ))}
      </Row>
    </Collapse>

    {order && order[0] && (
      <Subscription
        key={order[0].id}
        account={account}
        country={country}
        currency={currency}
        product={order[0]}
        productId={state.uniqueProduct.productId}
        role={role}
        updateOrder={updateOrder}
      />
    )}
    {state.showQuantityInput && (
      <Fragment>
        <Row>
          <Col>
            <p className="text-muted">Quantity</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              min="1"
              onChange={e => {
                updateOrder(order[0].id, {
                  quantity: e.target.value
                })
                effects.chooseQuantity(state.uniqueProduct.productId, e.target.value)
              }}
              required
              style={{ width: '100px' }}
              type="number"
              value={state.uniqueProduct.quantity}
            />
          </Col>
        </Row>
      </Fragment>
    )}
    <br />
    {!initialOrder && (
      <BindingChoice
        handleSelfbound={handleSelfbound}
        role={role}
        selfbound={selfbound}
      />
    )}
  </Fragment>
)

export default withState(injectState(PlanDetails))
