import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Footer = () => (
  <Container fluid>
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <footer className="footer">
          <span className="text-muted">
            If you need help, <a href="mailto:sales@vates.fr">contact</a> us or
            ask in the chat. Xen Orchestra is a product with no commitment. If
            you choose to pay on a monthly base, you can only use a Credit Card
            to complete your order. Once you order have been validated, you will
            received a confirmation email. The invoices are send by email and
            are also available on your personal space on the "invoices" tab.
          </span>
        </footer>
      </Col>
    </Row>
  </Container>
)

export default Footer
