import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { injectStripe } from 'react-stripe-elements'
import { Order } from '@vates/www-xo-utils'

import { getApi } from '../../api'
import BillingInformation from './billing-information'
import PlanDetails from './plan-details'
import Stepper from './stepper'
import Payment from './payment'
import Recap from './recap'
import Summary from './summary'
import {
  isValidBillingInfo,
  addOrReplaceStripeCard,
  PAID_PERIOD,
  STRIPE,
  SUBSCRIPTION,
  TRANSFER,
  stripeSecureAuthentication,
  validateOrder
} from '../purchase-utils'
import ConfirmModal from '../components/confirm-modal'
import CreditCardRecap from './payment/credit-card-recap'
import { makeStepperData } from '../../utils'

export const PLAN_DETAIL = 1
export const BILLING_INFO = 2
export const PAYMENT_METHOD = 3
export const CREDIT_CARD_RECAP = 3.1
export const RECAP = 4

const STEPS_PROPS_TEMPLATE = {
  [PLAN_DETAIL]: {
    state: {
      account: '',
      country: '',
      currency: '',
      initialOrder: '',
      order: '',
      preSelectedProduct: '',
      role: '',
      selfbound: '',
      xwToken: ''
    },
    effects: {
      addToOrder: '',
      handleSelfbound: '',
      resetOrder: '',
      updateOrder: ''
    }
  },
  [BILLING_INFO]: {
    state: {
      account: '',
      baseUrl: '',
      country: '',
      role: '',
      xwToken: ''
    },
    effects: {
      updateBillingInfo: ''
    }
  },
  [PAYMENT_METHOD]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {
      updatePayment: ''
    }
  },
  [CREDIT_CARD_RECAP]: {
    state: {},
    effects: {}
  },
  [RECAP]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {}
  }
}

const STEPS = [
  {
    stepComponent: PlanDetails,
    stepIndex: PLAN_DETAIL,
    stepName: 'Plan details',
    onNextStep: (effects, state) => {
      if (validateOrder(state._order)) {
        effects.goToStep(BILLING_INFO)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(PLAN_DETAIL)
    }
  },
  {
    stepComponent: BillingInformation,
    stepIndex: BILLING_INFO,
    stepName: 'Billing information',
    nextText: state => (state._order?.id ? 'Update order' : 'Save order'),
    onNextStep: async (effects, state) => {
      if (isValidBillingInfo(state.account)) {
        try {
          await getApi().updateAccountInfo(
            state.xwToken,
            state.account.billingInfo
          )

          if (state._order.id) {
            // already first-placed
            if (!state._order.isCartLocked) {
              await getApi(state._order.role)._updateOrder(state._order)
            }
          } else {
            const order = await getApi(state._order.role)._placeOrder(
              state.xwToken,
              state._order
            )

            await effects.refreshAccountInfo() // Refresh data to update first currency set in placeOrder

            effects.setOrderId(order.id) // ??
            effects.refreshOrder(order)
          }
          effects.handleChangeOrderSaved(true)
          effects.goToStep(PAYMENT_METHOD)
        } catch (error) {
          effects.handleError(error)
        }
      }
    },
    onPreviousStep: (effects, state) => {
      if (state._order?.isCartLocked || state.initialOrder?.isCartLocked) {
        if (state._order?.onUpgrade) {
          effects.notify(
            'info',
            'You cannot edit your order because this is an upgrade'
          )
        } else {
          effects.notify(
            'info',
            'You cannot edit your order because this is an order created from a quote'
          )
        }
      } else {
        effects.goToStep(PLAN_DETAIL)
      }
    }
  },
  {
    stepComponent: Payment,
    stepIndex: PAYMENT_METHOD,
    stepName: 'Payment method',
    nextText: () => 'Confirm',
    onNextStep: async (effects, state) => {
      try {
        /**
         * Transfer payment
         */
        if (state.payment.paymentMethod === TRANSFER) {
          if (state._order.status !== Order.ORDER_STATUS.TRANSFER_EXPECTED) {
            const order = await getApi(state.role).chooseTransferPayment(
              state._order.id
            )
            await effects.refreshOrder(order)
          }

          effects.goToStep(RECAP)
        }

        /**
         * Credit card payment
         */
        if (state.payment.paymentMethod === STRIPE) {
          if (state._order.status !== Order.ORDER_STATUS.CARD_EXPECTED) {
            const order = await getApi(state.role).chooseCardPayment(
              state._order.id
            )
            await effects.refreshOrder(order)
          }

          /**
           * If user want to use new card, we add it or replace the existing card
           */
          if (state.payment.usePreviousCard !== true) {
            await effects.createStripeToken(state.account.billingInfo.lastName)
            await addOrReplaceStripeCard(
              state.xwToken,
              state.account,
              state.payment.cardToken
            )
            await effects.refreshAccountInfo()
          }

          effects.goToStep(CREDIT_CARD_RECAP)
        }
        effects.handleChangeOrderSaved(true)
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(BILLING_INFO)
    }
  },
  {
    stepHidden: true,
    stepComponent: CreditCardRecap,
    stepIndex: CREDIT_CARD_RECAP,
    stepName: 'Payment method',
    nextText: () => 'Pay',
    onNextStep: async (effects, state, props) => {
      try {
        let order
        if (state._order.paymentModel.type === PAID_PERIOD) {
          order = await getApi(state.role).intentOneShotPayment(
            state.xwToken,
            state._order.id
          )

          // If 3D secure
          if (order.clientSecret) {
            await stripeSecureAuthentication(
              props.stripe.handleCardPayment,
              order.clientSecret
            )
            order = await getApi(state.role).confirmOneShotPayment(
              state.xwToken,
              state._order.id
            )
          }
        } else if (state._order.paymentModel.type === SUBSCRIPTION) {
          order = await getApi(state.role).initCreditCardPayment(
            state.xwToken,
            state._order.id
          )
          // If 3D secure
          if (order.clientSecret) {
            const answer = await stripeSecureAuthentication(
              props.stripe.handleCardPayment,
              order.clientSecret
            )
            order = await getApi(state.role).confirmRecurringPayment(
              state.xwToken,
              state._order.id,
              answer.paymentIntent
            )
          }

          // Selfbound order
          if (order.selfbound && order.status === Order.ORDER_STATUS.UP) {
            await getApi(state.role).bindEndUserToOrder(
              state.xwToken,
              state.email,
              order.id
            )
          }
        } else {
          effects.goToStep(PAYMENT_METHOD)
        }

        effects.refreshOrder(order)
        effects.goToStep(RECAP)
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(PAYMENT_METHOD)
    }
  },
  {
    stepComponent: Recap,
    stepIndex: RECAP,
    stepName: 'Recap',
    nextText: () => 'Go to account',
    onNextStep: async (effects, state) => {
      try {
        effects.redirectToAccount()
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: (effects, state) => {},
    disablePreviousButton: true
  }
]

const getCorrectstep = (account, _order) => {
  if (
    _order.status === Order.ORDER_STATUS.PLACED ||
    _order.status === Order.ORDER_STATUS.TRANSFER_EXPECTED
  ) {
    return PAYMENT_METHOD
  }
  if (
    _order.status === Order.ORDER_STATUS.CARD_EXPECTED ||
    _order.status === Order.ORDER_STATUS.CARD_RECURRING_PAYMENT_PENDING ||
    _order.status === Order.ORDER_STATUS.CARD_ONESHOT_PAYMENT_PENDING
  ) {
    if (account.stripe && account.stripe.card) {
      return CREDIT_CARD_RECAP
    }
    return PAYMENT_METHOD
  }

  return PLAN_DETAIL
}

const getStep = currentStep =>
  STEPS.find(step => step.stepIndex === currentStep)

const withState = provideState({
  initialState: () => ({
    currentStep: 1,
    waiting: false,
    modalAction: undefined,
    modalActionArgs: [],
    modalBody: undefined,
    modalStatus: false
  }),
  effects: {
    async initialize(effects) {
      if (this.state.forceStep) {
        this.state.currentStep = this.state.forceStep
      } else {
        effects.initCurrentStep()
      }
    },
    initCurrentStep(_) {
      if (this.state._order) {
        this.state.currentStep = getCorrectstep(
          this.state.account,
          this.state._order
        )
      }
    },
    async previous(effects) {
      this.state.waiting = true
      getStep(this.state.currentStep).onPreviousStep(effects, this.state)
      this.state.waiting = false
    },
    async next(effects) {
      this.state.waiting = true
      await getStep(this.state.currentStep).onNextStep(
        effects,
        this.state,
        this.props
      )
      this.state.waiting = false
    },
    goToStep(_, stepNumber) {
      this.state.currentStep = stepNumber
    },
    async createStripeToken(effects, lastname) {
      try {
        const { token } = await this.props.stripe.createToken({
          name: lastname
        })
        if (token) {
          effects.updatePayment({
            cardToken: token.id,
            cardLegalInfo: token.card
          })
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    confirm(_, modalAction, modalActionArgs, modalBody) {
      this.state.modalAction = modalAction
      this.state.modalActionArgs = modalActionArgs
      this.state.modalBody = modalBody
      this.state.modalStatus = true
    },
    closeModal() {
      this.state.modalStatus = false
      this.state.modalAction = undefined
      this.state.modalActionArgs = []
      this.state.modalBody = undefined
    }
  }
})

const NewPurchase = ({ effects, state }) => (
  <Container fluid>
    <Col md={{ size: 10, offset: 1 }}>
      <Row>
        <Col md="8">
          <Stepper
            currentStep={state.currentStep}
            next={effects.next}
            previous={effects.previous}
            props={makeStepperData(state, effects, STEPS_PROPS_TEMPLATE)}
            steps={STEPS}
            waiting={state.waiting}
          />
        </Col>
        <Col md="4">
          <div style={{ marginTop: '8em' }}>
            <Summary billingInfo={state.account.billingInfo} />
          </div>
        </Col>
      </Row>
    </Col>
    <ConfirmModal
      close={effects.closeModal}
      modalAction={state.modalAction}
      modalActionArgs={state.modalActionArgs}
      modalBody={state.modalBody}
      modalStatus={state.modalStatus}
    />
  </Container>
)

export default injectStripe(withState(injectState(NewPurchase)))
